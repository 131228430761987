// App.js
import './App.css';
import React from 'react';
import SubmissionForm from './components/SubmissionForm.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div className="App">
      <h1>RoastFully.com - Very ATS "Friendly"</h1>
      <p>Warning: Brutal, not for weak</p>
      <SubmissionForm />
      <ToastContainer />
    </div>
  );
}

export default App;




















// // App.js
// import './App.css';
// import React, { useState } from 'react';
// import SubmissionForm from './components/SubmissionForm.js';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// function App() {
//   return (
//     <div className="App">
//       <h1>Roastfully.com</h1>
//       <p>Throw your Resume to get roasted! (Warning: Its Brutal)</p>
//       <SubmissionForm />
//       <ToastContainer />
//     </div>
//   );
// }

// export default App;