import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

const SubmissionForm = () => {
  const [roastLevel, setRoastLevel] = useState('medium');
  const [resume, setResume] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [roast, setRoast] = useState('');
  const [roastSentences, setRoastSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [shuffledImages, setShuffledImages] = useState([]);
  
  const roastContainerRef = useRef(null); // Ref to scroll as new content appears
  const bottomRef = useRef(null); // Ref for final scroll to the "Roast again" button

  const images = [
    "https://i.gifer.com/3InG.gif",
    "https://i.gifer.com/jU.gif",
    "https://i.gifer.com/4HZ.gif",
    "https://i.gifer.com/2XZ2.gif",
    "https://i.gifer.com/qFk.gif",
    "https://i.gifer.com/7CT5.gif",
    "https://i.gifer.com/2usW.gif",
    "https://i.gifer.com/jW.gif",
    "https://i.gifer.com/6Yve.gif",
    "https://i.gifer.com/9DeW.gif",
    "https://i.gifer.com/S5v.gif",
    "https://i.gifer.com/5D0t.gif",
    "https://i.gifer.com/2Wtl.gif",
    "https://i.gifer.com/5JHk.gif",
    "https://i.gifer.com/OtO.gif",
    "https://i.gifer.com/fyOG.gif"


  ];

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
    setSubmitted(false);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!resume) {
      setError('Please upload your resume');
      return;
    }

    setError('');
    toast.success(`Roast is cooking, hold tight!`, {
      position: "top-center",
      autoClose: 5000,
    });

    const formData = new FormData();
    formData.append('roastLevel', roastLevel);
    formData.append('resume', resume);

    try {
      const response = await fetch('https://roastfully-backend.onrender.com/api/submit-profile', { // http://localhost:5000/api/submit-profile 'https://roastfully-backend.onrender.com/api/submit-profile'
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      setRoast(data.roast); 
      setRoastSentences(data.roast.split('. '));
      setSubmitted(true);
      setCurrentSentenceIndex(0);
      setShuffledImages(shuffleArray([...images]));
    } catch (error) {
      console.error('Error submitting profile:', error);
      setError('There was an issue generating your roast. Please try again later.');
    }
  };

  useEffect(() => {
    if (roastSentences.length > 0 && currentSentenceIndex < roastSentences.length) {
      const sentenceLength = roastSentences[currentSentenceIndex].length;
      const timer = setTimeout(() => {
        setCurrentSentenceIndex((prev) => prev + 1);
      }, sentenceLength * 50);

      return () => clearTimeout(timer);
    }
  }, [currentSentenceIndex, roastSentences.length]);

  useEffect(() => {
    // Auto-scroll to the last displayed sentence or "Roast again" button
    if (roastContainerRef.current) {
      roastContainerRef.current.scrollTop = roastContainerRef.current.scrollHeight;
    }

    // Final scroll to the "Roast again" button after last sentence
    if (currentSentenceIndex >= roastSentences.length - 1 && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentSentenceIndex]);

  const handleRoastAgain = () => {
    setRoast('');
    setRoastSentences([]);
    setSubmitted(false);
  };

  const handleSkipToEnd = () => {
    setCurrentSentenceIndex(roastSentences.length - 1);
  };

  return (
    <div>
      {submitted ? (
        <div className="thank-you">
          <h3> BRACE YOURSELF</h3>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {error && <p className="error">{error}</p>}

          <div className="form-group">
            <label htmlFor="roast-level">Roast Level</label>
            <select
              id="roast-level"
              value={roastLevel}
              onChange={(e) => {
                setRoastLevel(e.target.value);
                setSubmitted(false);
                setError('');
              }}
            >
              <option value="mild">Baby</option>
              <option value="medium">Brutal</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="resume">Upload Resume (PDF only)</label>
            <input
              type="file"
              id="resume"
              accept=".pdf"
              onChange={handleFileChange}
              required
            />
          </div>

          <button type="submit">Roast me</button>
          {/* GIF displayed below the submit button */}
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <img src="https://i.gifer.com/WgW1.gif" alt="Roast GIF" style={{ width: '300px', height: 'auto' }} />
          </div>
        </form>
      )}

      {/* Display the roast sentence-by-sentence with images */}
      {roast && (
        <div
          className="roast-result"
          ref={roastContainerRef}
          style={{ maxHeight: '80vh', overflowY: 'auto', paddingBottom: '20px' }}
        >
          {roastSentences.slice(0, currentSentenceIndex + 1).map((sentence, index) => (
            <div key={index} id={`sentence-${index}`} className="roast-section fade-in">
              <p>{sentence}.</p>
              {shuffledImages[index % shuffledImages.length] && (
                <img src={shuffledImages[index % shuffledImages.length]} alt="Roast GIF" className="roast-gif" style={{ width:'300px', height: 'auto' }} />
              )}
            </div>
          ))}
          {currentSentenceIndex >= roastSentences.length - 1 ? (
            <button onClick={handleRoastAgain} style={{ marginTop: '20px', marginBottom: '50px' }}>Roast again</button>
          ) : (
            <button onClick={handleSkipToEnd}>Skip to End</button>
          )}
          <div ref={bottomRef}></div> {/* Ensure extra space at the bottom */}
        </div>
      )}
    </div>
  );
};

export default SubmissionForm;













// working code for resume and roast level input
// // components/SubmissionForm.js
// import React, { useState } from 'react';
// import { toast } from 'react-toastify';

// const SubmissionForm = () => {
//   const [roastLevel, setRoastLevel] = useState('medium');
//   const [resume, setResume] = useState(null); // State for resume file
//   const [submitted, setSubmitted] = useState(false);
//   const [error, setError] = useState('');
//   const [roast, setRoast] = useState(''); // Added to store the roast response

//   const handleFileChange = (e) => {
//     setResume(e.target.files[0]);
//     setSubmitted(false); // Reset form state when file changes
//     setError(''); // Clear error on file change
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Check if resume is uploaded
//     if (!resume) {
//       setError('Please upload your resume');
//       return;
//     }

//     // Reset errors and show toast for submission
//     setError('');
//     toast.success(`Roast successfully submitted!`, {
//       position: "top-center",
//       autoClose: 5000,
//     });

//     // FormData to send file and other inputs
//     const formData = new FormData();
//     formData.append('roastLevel', roastLevel);
//     formData.append('resume', resume); // Attach the file

//     try {
//       // Make the API call to backend to submit the roast level and resume
//       const response = await fetch('https://roastfully-backend.onrender.com/api/submit-profile', { // for local use http://localhost:5000/api/submit-profile
//         method: 'POST',
//         body: formData, // Use formData instead of JSON for file uploads
//       });

//       const data = await response.json();
//       setRoast(data.roast); // Store the roast returned from backend
//       setSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting profile:', error);
//       setError('There was an issue generating your roast. Please try again later.');
//     }
//   };

//   return (
//     <div>
//       {submitted ? (
//         <div className="thank-you">
//           <h3>...alright, BRACE YOURSELF</h3>
//         </div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           {error && <p className="error">{error}</p>}

//           <div className="form-group">
//             <label htmlFor="roast-level">Roast Level</label>
//             <select
//               id="roast-level"
//               value={roastLevel}
//               onChange={(e) => {
//                 setRoastLevel(e.target.value);
//                 setSubmitted(false); // Reset form state if level changes
//                 setError(''); // Clear error on selection change
//               }}
//             >
//               <option value="mild">Baby</option>
//               <option value="medium">Brutal</option>
//             </select>
//           </div>

//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume (PDF only)</label>
//             <input
//               type="file"
//               id="resume"
//               accept=".pdf"
//               onChange={handleFileChange}
//               required
//             />
//           </div>

//           <button type="submit">Submit Profile for Roast</button>
//         </form>
//       )}

//       {/* Display the roast after submission */}
//       {roast && (
//         <div className="roast-result">
//           <p>{roast}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SubmissionForm;









































// working code only for roast level and resume input

// // components/SubmissionForm.js
// import React, { useState } from 'react';
// import { toast } from 'react-toastify';

// const SubmissionForm = () => {
//   const [roastLevel, setRoastLevel] = useState('medium');
//   const [resume, setResume] = useState(null); // State for resume file
//   const [submitted, setSubmitted] = useState(false);
//   const [error, setError] = useState('');
//   const [roast, setRoast] = useState(''); // Added to store the roast response

//   const handleFileChange = (e) => {
//     setResume(e.target.files[0]);
//     setSubmitted(false); // Reset form state when file changes
//     setError(''); // Clear error on file change
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Check if resume is uploaded
//     if (!resume) {
//       setError('Please upload your resume');
//       return;
//     }

//     // Reset errors and show toast for submission
//     setError('');
//     toast.success(`Roast successfully submitted!`, {
//       position: "top-center",
//       autoClose: 5000,
//     });

//     // FormData to send file and other inputs
//     const formData = new FormData();
//     formData.append('roastLevel', roastLevel);
//     formData.append('resume', resume); // Attach the file

//     try {
//       // Make the API call to backend to submit the roast level and resume
//       const response = await fetch('http://localhost:5000/api/submit-profile', { // for local use http://localhost:5000/api/submit-profile
//         method: 'POST',
//         body: formData, // Use formData instead of JSON for file uploads
//       });

//       const data = await response.json();
//       setRoast(data.roast); // Store the roast returned from backend
//       setSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting profile:', error);
//       setError('There was an issue generating your roast. Please try again later.');
//     }
//   };

//   return (
//     <div>
//       {submitted ? (
//         <div className="thank-you">
//           <h3>...alright, BRACE YOURSELF</h3>
//         </div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           {error && <p className="error">{error}</p>}

//           <div className="form-group">
//             <label htmlFor="roast-level">Roast Level</label>
//             <select
//               id="roast-level"
//               value={roastLevel}
//               onChange={(e) => {
//                 setRoastLevel(e.target.value);
//                 setSubmitted(false); // Reset form state if level changes
//                 setError(''); // Clear error on selection change
//               }}
//             >
//               <option value="mild">Baby</option>
//               <option value="medium">Brutal</option>
//             </select>
//           </div>

//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume (PDF only)</label>
//             <input
//               type="file"
//               id="resume"
//               accept=".pdf"
//               onChange={handleFileChange}
//               required
//             />
//           </div>

//           <button type="submit">Submit Profile for Roast</button>
//         </form>
//       )}

//       {/* Display the roast after submission */}
//       {roast && (
//         <div className="roast-result">
//           <p>{roast}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SubmissionForm;

































// code for resume, roast level and linked in url inputs

// // components/SubmissionForm.js
// import React, { useState } from 'react';
// import { toast } from 'react-toastify';

// const SubmissionForm = () => {
//   const [linkedinURL, setLinkedinURL] = useState('');
//   const [roastLevel, setRoastLevel] = useState('medium');
//   const [resume, setResume] = useState(null); // State for resume file
//   const [submitted, setSubmitted] = useState(false);
//   const [error, setError] = useState('');
//   const [roast, setRoast] = useState(''); // Added to store the roast response

//   const handleFileChange = (e) => {
//     setResume(e.target.files[0]);
//     setSubmitted(false); // Reset form state when file changes
//     setError(''); // Clear error on file change
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Basic validation for LinkedIn URL format
//     const linkedinRegex = /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
//     if (!linkedinRegex.test(linkedinURL)) {
//       setError('Please enter a valid LinkedIn profile URL');
//       return;
//     }

//     // Check if resume is uploaded
//     if (!resume) {
//       setError('Please upload your resume');
//       return;
//     }

//     // Reset errors and show toast for submission
//     setError('');
//     toast.success(`Roast successfully submitted for: ${linkedinURL}`, {
//       position: "top-center",
//       autoClose: 5000,
//     });

//     // FormData to send file and other inputs
//     const formData = new FormData();
//     formData.append('linkedinURL', linkedinURL);
//     formData.append('roastLevel', roastLevel);
//     formData.append('resume', resume); // Attach the file

//     try {
//       // Make the API call to backend to submit the LinkedIn profile, roast level, and resume
//       const response = await fetch('https://roastfully-backend.onrender.com/api/submit-profile', {
//         method: 'POST',
//         body: formData, // Use formData instead of JSON for file uploads
//       });

//       const data = await response.json();
//       setRoast(data.roast); // Store the roast returned from backend
//       setSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting profile:', error);
//       setError('There was an issue generating your roast. Please try again later.');
//     }
//   };

//   return (
//     <div>
//       {submitted ? (
//         <div className="thank-you">
//           <h3>Thanks for submitting! We are roasting your profile...</h3>
//         </div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="linkedin-url">LinkedIn Profile URL</label>
//             <input
//               type="text"
//               id="linkedin-url"
//               value={linkedinURL}
//               onChange={(e) => {
//                 setLinkedinURL(e.target.value);
//                 setSubmitted(false); // Reset form state if URL changes
//                 setError(''); // Clear error on input change
//               }}
//               placeholder="https://www.linkedin.com/in/your-profile"
//               required
//             />
//           </div>
//           {error && <p className="error">{error}</p>}

//           <div className="form-group">
//             <label htmlFor="roast-level">Roast Level</label>
//             <select
//               id="roast-level"
//               value={roastLevel}
//               onChange={(e) => {
//                 setRoastLevel(e.target.value);
//                 setSubmitted(false); // Reset form state if level changes
//                 setError(''); // Clear error on selection change
//               }}
//             >
//               <option value="mild">Mild</option>
//               <option value="medium">Medium</option>
//               <option value="savage">Savage</option>
//             </select>
//           </div>

//           <div className="form-group">
//             <label htmlFor="resume">Upload Resume (PDF only)</label>
//             <input
//               type="file"
//               id="resume"
//               accept=".pdf"
//               onChange={handleFileChange}
//               required
//             />
//           </div>

//           <button type="submit">Submit Profile for Roast</button>
//         </form>
//       )}

//       {/* Display the roast after submission */}
//       {roast && (
//         <div className="roast-result">
//           <p>{roast}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SubmissionForm;

























